body {
    /* background: linear-gradient(to right, #85D8CE, #085078); */
    font-family: GreycliffCF;
    background: linear-gradient(#310A36 0%, #310A36 50%, #72207E 100%) no-repeat;
    color: white;
    min-height: 100vh;
    font-size: 20px;
}
@media only screen and (max-width: 600px) {
    .playlist-container-mobile *, #home-mobile-tab *{
        font-size: 1rem!important;
    }
    .table-list-playlist {
        width: 100%;
        max-width: 100%;
    }
    .table-list-playlist tr {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    
}